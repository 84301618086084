import { useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';

export const useIdleLogout = () => {
const history = useHistory();
  const idleTime = useRef(0);

  useEffect(() => {
  
    window.addEventListener("click", () => {
      idleTime.current = 0;
    });
    window.addEventListener("mousemove", () => {
      idleTime.current = 0;
    });
    console.log(idleTime);
    const tick = setInterval(() => {
      if (idleTime.current > 1000 * 60 * 10) {
        console.log(idleTime);
        sessionStorage.clear();
        window.location.href = '/';
        history.push('/');
        clearInterval(tick);
        idleTime.current = 0;
      }
      idleTime.current += 1000;
    }, 1000);
    return () => {
      window.removeEventListener("click", () => {
        idleTime.current = 0;
      });
      window.removeEventListener("mousemove", () => {
        idleTime.current = 0;
      });
      clearInterval(tick);
    };
  }, []);
};

import swal from 'sweetalert';

import { getAccountDetails } from '../../APIs/accountsAPI';
import handleErrorCodes from '../../helpers/handleErrorCodes';
import actionLabels from './actionLabels';

export const accountDetails = user => {
  return dispatch => {
    return getAccountDetails(user)
      .then(body => body.json())
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          const KYBStatus =
            data.kycstatus.kybStatus || data.kycstatus.fullDueDiligence;
          dispatch(setAccountDetails(data));
          dispatch(setKYB(KYBStatus));
        } else {
          handleErrorCodes(data, dispatch, user);
        }
      })
      .catch(e => {
        swal(
          'Error',
          'We have encountered some error, you might face more error because of this one!',
          'error'
        );
      });
  };
};

export const setAccountDetails = accountDetails => ({
  type: actionLabels.SET_ACCOUNT_DETAILS,
  payload: { accountDetails },
});

export const setKYB = completedKYB => ({
  type: actionLabels.SET_KYB,
  payload: { completedKYB },
});

import { combineReducers } from 'redux';
import cardReducer from './cardReducer';
import contoEnjoyFeesReducer from './contoEnjoyFeesReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  userState: userReducer,
  cardState: cardReducer,
  feesState: contoEnjoyFeesReducer,
});

export default rootReducer;

import { logoutUser } from '../../APIs/accountsAPI';
import actionLabels from './actionLabels';

export const setUser = user => ({
  type: actionLabels.SET_USER,
  payload: { user },
});

export const setRegisteredUser = registeredUser => ({
  type: actionLabels.SET_REGISTERED_USER,
  payload: { registeredUser },
});

export const logout = user => {
  return dispatch => {
    dispatch({ type: actionLabels.LOGOUT });
    return logoutUser(user).catch(error => {
      console.log(error);
    });
  };
};

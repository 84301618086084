import React, { useState } from 'react'

const $ = window.jQuery


export default function LangagueModel() {

    const [language, setLanguage] = useState("")
    const [languagecode, setLanguagecode] = useState("")

    const changeLanguage = async (element) =>{
        if(element == 'english'){
            setLanguage(element);
            setLanguagecode('en');
        }else{
            setLanguage(element);
            setLanguagecode('it');
        }
    }
    const submitLanguage = async () =>{
        window.localStorage.setItem('language', language);
        window.localStorage.setItem('languagecode', languagecode);
        window.localStorage.setItem('i18nextLng', languagecode);
        $("#myModal").modal('hide');
        // window.location.href = '/';
        window.location.reload();
    }

  return (
    <div>
        <div id="exampleModalCenter" className="modal fade" data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Choose You Preferd Language</h5>
                        </div>
                        <div className="modal-body">

                            <div className="form-group">
                                <select className="form-control"
                                value={language}
                                onChange={(e) => changeLanguage(e.target.value) }
                                >
                                    <option value="null">Select Language</option>
                                    <option value="english">English</option>
                                    <option value="italian">Italian</option>
                                </select>
                            </div>
                            <div class="modal-footer">
                            <button type="submit" className="btn btn-primary center" onClick={submitLanguage}>Select</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
  )
}

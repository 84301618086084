import React, { memo, useState } from 'react';

import logo from '../../assets/images/logo.png';
import SideMenuListItem from './SideMenuListItem';

function SideMenus() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <header>
        <div className='header_inner d-new-a'>
          <div className='header_left d-align'>
            <div className='toggle_menu' onClick={toggleOpen}>
              <label>&#9776;</label>
            </div>
            <div className='h_logo'>
              <img src={logo} alt='' />
            </div>
          </div>
        </div>
      </header>
      <section className='content_outer'>
        <div className={`sidebar ${open && 'active'}`}>
          <div className='logo'>
            <img src={logo} alt='' />
          </div>
          <div className='menu'>
            <ul>
              <SideMenuListItem />
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default memo(SideMenus);

import { contoJoyFeesURL } from '../constants/urls';

export const contoEnjoyFees = (payload = {}) => {
  return fetch(contoJoyFeesURL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(body => body.json());
};

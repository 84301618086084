import React, { memo } from 'react';

import './spinner.css';

function Spinner(props) {
  const { size } = props;

  return <div className='spinner' style={{ height: size, width: size }}></div>;
}

Spinner.defaultProps = {
  size: 19,
};

export default memo(Spinner);

const baseURL = process.env.REACT_APP_API_URL;

export const authenticationURLs = {
  CREATE_CORPORATE_IDENTITY: baseURL + '/register/corporate',
  CREATE_CONSUMER_IDENTITY: baseURL + '/register/consumer',
  VERIFY_EMAIL: baseURL + '/register/verify/email',
  LOGIN_CONSUMER_OR_CORPORATE: baseURL + '/login',
  SEND_MOBILE_OTP: baseURL + '/register/verify/phone/send',
  VERIFY_MOBILE_OTP: baseURL + '/register/verify/phone',
  FORGOT_PASSWORD: baseURL + '/forget-password',
  SET_PASSWORD: baseURL + '/set-password',
  RESEND_OTP:baseURL + '/resend/otp',
  SETUP_TOKEN_OPT_SEND: baseURL + '/register/verify/phone/otp/send',
  SETUP_TOKEN_OPT_VERIFY: baseURL + '/register/verify/phone/otp',
};

export const accountDetailsURLS = {
  ACCOUNT_DETAIL: baseURL + '/account/details',
  UPDATE_CORPORATE_ACCOUNT: baseURL + '/account/corporate/update',
  UPDATE_CONSUMER_ACCOUNT: baseURL + '/account/consumer/update',
  UPDATE_PASSWORD: baseURL + '/password/update',
  LOGOUT: baseURL + '/logout',
  UPGRADE_KYC: baseURL + '/upgrade/account/kyc',
  INITIATE_KYB: baseURL + '/register/initiate/kyc',
  ENROLL_PHONE_SEND: baseURL + '/register/authentication_factors/phone/otp/send',
  ENROLL_PHONE_VERIFY: baseURL + '/register/authentication_factors/verify/phone/otp',
  // ENROLL_PHONE_SEND: baseURL + '/account/enroll/phone/send',
  // ENROLL_PHONE_VERIFY: baseURL + '/account/enroll/phone/verify',
};

export const cardURLs = {
  CREATE_NEW_CARD: baseURL + '/create/card',
  CREATE_PHYSICAL_CARD: baseURL + '/secondary/physical/card',
  BLOCK_CARD: baseURL + '/block/card',
  UNBLOCK_CARD: baseURL + '/unblock/card',
  REMOVE_CARD: baseURL + '/remove/card',
  CARD_DETAILS: baseURL + '/card/detail',
  PHYSICAL_CARD_ACTIVATE: baseURL + '/physical/card',
  REPLACE_CARD: baseURL + '/card/replace',
  ALL_CARD: baseURL + '/all/card/detail',
};

export const transfersURLs = {
  CREATE_TRANSFER: baseURL + '/create/transfer/transaction',
  SINGAL_CARD_SATEMENT: baseURL + '/managed/card/statement',
  GET_ALL_TRANSFER_DETAILS: baseURL + '/all/managed/card/statement',
  GET_TRANSFER_DETAIL: baseURL + '/transfer/transaction/detail',
  PDF: baseURL + '/all/transfer/transaction/pdf',
  CSV: baseURL + '/all/transfer/transaction/csv',
  STATEMENT_DETAIL: baseURL + '/statement/details',
};

export const wireTransferURLs = {
  CREATE_WIRE_TRANSFER: baseURL + '/create/wire/transfer/transaction',
  GET_ALL_WIRE_TRANSACTIONS: baseURL + '/all/managed/account/statement',
  GET_WIRE_TRANSACTION: baseURL + '/wire/transfer/transaction/detail',
  VERIFY_WIRE_TRANSFER: baseURL + '/wire/verify_transfer_transaction',
};

export const stripePaymentURLs = {
  CREATE_PAYMENT: baseURL + '/fees/payment/stripe',
  CONFIRM_PAYMENT: baseURL + '/fees/payment/stripe/confirm',
};

export const trustPaymentURLs = {
  CREATE_PAYMENT: baseURL + '/fees/payment/trustconnect',
  CONFIRM_PAYMENT: baseURL + '/fees/payment/trustconnect/confirm',
};

export const contoJoyFeesURL = baseURL + '/all/fees';

import { t } from "i18next";
import React, { memo } from "react";

function Footer() {
  return (
    <footer>
      <div className="inner-footer mt-3 p-2" style={{ textAlign: "center" }}>
        <div className="footer-box">
          <p>{t("footer_text1")}</p>
          <p>{t("footer_text2")}</p>
          <p>{t("footer_text3")}</p>
          <p>{t("footer_text4")}</p>
        </div>

        <div className="footer-box">
          <p>{t("footer_text5")}</p>
          <p>{t("footer_text6")}</p>
        </div>
        <div className="footer-box">
          <p>{t("footer_text7")}</p>
        </div>
        <div className="footer-last-box">
          <p>{t("footer_text8")}</p>
        </div>
      </div>
    </footer>
  );
}

export default memo(Footer);

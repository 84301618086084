import actionLabels from '../actions/actionLabels';

const initialState = {
  cards: [],
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionLabels.SET_CARDS:
      return {
        ...state,
        cards: action.payload.cards,
      };
    case actionLabels.LOGOUT:
      return { cards: [] };
    default:
      return state;
  }
};

export default cardReducer;

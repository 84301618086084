export const TRANSLATIONS_IT = {
  login_text: "Inserisca il Suo Nome utente e password per accedere.",
  login_username_text: "Nome utente o indirizzo email",
  login_passowrd_text: "Password",
  palceholder_passowrd_text: "Password*",
  enter_here_text: "Inserisca la Sua email*",
  login: "LOGIN",
  fpassword_text: "Password dimenticata",
  note: "Nota",
  note_before: "i campi contrassegnati con",
  note_after: "sono obbligatori",
  hi_text: "Salve!",
  logout_text: "Uscire",
  account_details_text: "Dettagli del conto",
  full_name_text: "Nome e cognome",
  company_name_text: "Denominazione dell società",
  address_line_1: "Indirizzo 1",
  address_line_2: "Indirizzo 2",
  city_text: "Città",
  postcode_text: "Cap",
  country_text: "Paese",
  mobile_number_text: "Cellulare",
  a_c_no_text: "ID number",
  IBAN_text: "IBAN",
  BIC_text: "BIC",
  account_balance_text: "Saldo del conto",
  currency_text: "Valuta",
  bank_transfer_text: "Rifornimento di fondi tramite bonifico bancario",
  bank_transfer_other_text:
    "Per rifornire il Suo conto ContoEnjoy deve inviare un bonifico dal Suo conto personale al conto IBAN del ContoEnjoy",
  transaction_history_text: "Storia delle transazioni",
  current_available_balance_text: "Saldo attuale disponibile",
  date_range_text:
    "Selezioni le date del periodo per rivedere le Sue transazioni",
  transaction_history_account_text: "Storia delle transazioni Conto",
  download_pdf_button_text:
    "SCARICHI LA STORIA DELLE TRANSAZIONI IN FORMATO PDF",
  download_csv_button_text:
    "SCARICHI LA STORIA DELLE TRANSAZIONI IN FORMATO CSV",
  txn_date_text: "Data della transazione",
  id_text: "Numero di identificazione",
  type_text: "Tipo",
  amount_text: "Somma",
  fee_text: "Tassa",
  beneficiary_text: "Beneficiario",
  transaction_history_cards_text: "Storia delle transazioni con carta",
  from_text: "Da",
  to_text: "A",
  go_text: "ANDARE",
  clear_text: "PULIRE",
  your_account_text: "Carte sul Suo conto",
  card_holder_name_text: "Nome del titolare della carta",
  card_number_text: "Numero di carta",
  card_id_text: "Numero di identificazione della carta",
  expiry_date_text: "Data di scadenza",
  status_text: "Stato",
  balance_text: "Saldo",
  actions_text: "Azioni",
  block_text: "Bloccare",
  destroy_text: "Distruggere",
  replace_card_text: "Sostituire la carta",
  view_card_details_text: "Visualizzi i dettagli della carta",
  physical_text: "Fisicamente",
  apply_new_card_text: "RICHIEDA LA CARTA",
  card_transfer_text: "TRASFERIMENTO CARTA VERSO CARTA",
  apply_for_new_card_text: "Apply for a new card",
  one_off_payment: "Effettui un unico pagamento",
  creditor_name_text: "Nome del creditore",
  creaditot_iban_text: "IBAN del creditore",
  bank_identification_text: "Codice identificativo della banca",
  transaction_reference_text: "Riferimento transazione",
  swift_code_text: "Codice identificativo della banca",
  reference_text: "Riferimento",
  submit_button_text: "INVIARE",
  update_password_text: "Aggiorni password",
  old_password_text: "Vecchia password",
  new_password_text: "Nuova password",
  password_enter_here_text: "Inserisca qui*",
  update_button_text: "Aggiornare",
  contact_us_text: "Ci contatti",
  call_text: "Ci chiami",
  support_text: "Supporto",
  fecebook_text: "Facebook",
  instragram_text: "Instagram",
  website_text: "Website",
  Forget_password_text: "Password dimenticata",
  enter_email: "Inserisca la Sua email",
  Forget_password_palceholder_text: "ad esempio nome@esempio.com",
  send_link_text: "INVII UN LINK",
  success_text: "Operazione riuscita!",
  forget_password_s_text:
    "Abbiamo inviato un link di recupero al Suo Indirizzo email!",
  ok_text: "OK",
  footer_text1:
    "The Payment account and Mastercard cards are issued by Paynetics AD (via Paystratus Group Ltd, a Technical Provider of Paynetics AD) with company address 76A James Bourchier Blvd, 1407 Sofia, Bulgaria, company registration number 131574695 and VAT BG131574695, pursuant to license by Mastercard International Incorporated and Bulgarian National Bank.",
  footer_text2:
    "To ensure your funds are safe Paynetics follow a process known as ‘safeguarding’ which is a regulatory requirement for all EMIs. In this process Paynetics keeps your money separate from its own money and place it in a safeguarding account with a Bank.",
  footer_text3:
    "Electronic Money issued is not covered by any Deposit Guarantee Scheme which is a government backed scheme offering protection to customers’ funds of up to € 100,000 (£ 85,000 in the UK under the FSCS) per customer. However, in the event of an insolvency, your funds would remain in the safeguarded account at the designated Bank and separated from Paynetics’ accounts.",
  footer_text4:
    "In the event of Paynetics going out of business, an insolvency practitioner would be appointed to return the funds Paynetics has safeguarded to the customers. This means you would get most of your funds back, except for the costs deducted by the insolvency practitioner for returning the funds to the customers.",
  footer_text5:
    "For programmes operating in the United Kingdom, the Payment Account and Mastercard cards are issued by Paynetics UK, Company number 1248133, (via Paystratus Group Ltd, a Technical Provider of Paynetics UK). Paynetics UK is a wholly owned subsidiary of Paynetics AD with its registered address at 1st Floor, 18 Devonshire Row, London, England, EC2M 4RH. Paynetics UK is an electronic money institution authorised and regulated by the Financial Conduct Authority (firm reference number 942777) for the issuance of e-money and provision of payment services in the UK.",
  footer_text6:
    "For programmes operating in the European Union, the Payment account and Mastercard cards are issued by Paynetics AD (via Paystratus Group Ltd, a Technical Provider of Paynetics AD) with company address 76A James Bourchier Blvd, 1407 Sofia, Bulgaria, company registration number 131574695 and VAT BG131574695, pursuant to license by Mastercard International Incorporated and Bulgarian National Bank.",
  footer_text7:
    "Weavr (Europe) Ltd is acting on behalf of Paynetics AD, as a registered agent on the territory of the EU member states, and is entered into the public register of e-money institutions’ agents kept by the Bulgarian National Bank.",
  footer_text8:
    "Copyright © 2022 ContoEnjoy White Financial UAB UK – TAY HOUSE 300 BATH STREET GLASGOW SCOTLAND G2 4JR – VAT NUMBER GB 432 7848 76 ",
  file_ready_title_text: "Il file è pronto",
  file_ready_text: "Fare clic sul pulsante di download per scaricare il file",
  cancel_button_text: "Annullare",
  download_button_text: "Scaricare",
  view_account_text: "Esamini il conto",
  complete_due_sidebar_text: "Completi l'attività di due diligence",
  transaction_history_sidebar_text: "Storia delle transazioni",
  all_card_sidebar_text: "Tutte le carte",
  payment_request_sidebar_text: "Richiesta di pagamento",
  update_password_sidebar_text: "Aggiorni password",
  term_and_condition_sidebar_text: "Termini e Condizioni",
  contact_us_sidebar_text: "Ci contatti",
  verify_email_text: "Prego verifichi la Sua email",
  verify_phone_text: "Verifica il tuo telefono",
  enter_code_here_text: "Inserisca qui il codice",
  verify_email_placeholder_text: "e.g. 123456",
  verify_button_text: "VERIFY",
  email_verify_success_message_text:
    "Il Suo indirizzo email è stato verificato con successo!",
  phone_verify_success_message_text:
    "Il tuo telefono è stato verificato con successo!",
  pay_your_fees_text: "Paga la Sua quota di iscrizione per continuare",
  card_details_text: "Informazioni dettagliate sulla Sua carta",
  payment_success_message_text: "Il Suo pagamento è riuscito!",
  enroll_phone_button_text: "INSERISCA IL NUMERO DI TELEFONO",
  setup_phone_token_button_text: "IMPOSTA IL TOKEN DEL TELEFONO",
  KYB_text: "KYB",
  KYC_text: "KYC",
  initiate_buttin_text: "INIZIATO",
  otp_send_mobile_text:
    "Abbiamo inviato la password monouso (OTP), controlla il tuo cellulare!",
  sent_text: "Spedito!",
  enter_otp_here_text: "Inserisca qui la password una tantum (OTP)",
  otp_text: "Password una tantum (OTP)",
  verify_enroll_button_text: "CONTROLLI L'ISCRIZIONE",
  verify_setup_token_button_text:
    "VERIFICA IL TOKEN DI CONFIGURAZIONE DEL TELEFONO",
  actual_balance_text: "Equilibrio effettivo",
  card_cvv_text: "Carta CVV",
  card_brand_text: "Marchio della carta",
  card_model_text: "Modalità Carta",
  card_type_text: "Tipo di carta",
  card_pin_text: "PIN carta",
  close_text: "Chiudi",
  apply_card_text: "Richiedi nuova carta",
  name_text: "Nome e Cognome sulla carta oppure azenda",
  billing_address_1_text: "Indirizzo 1",
  billing_address_2_text: "Indirizzo 2",
  post_code_text: "CAP",
  card_holder_mobile_no_text: "Cellulare",
  select_country_text: "Seleziona paese",
  loding_details_text: "Per modificare i dettagli",
  apply_button_text: "Applica",
  state_text: "Stato",
  select_dial_code_text: "Seleziona Codice di chiamata",

  card_to_card_text: "TRASFERIMENTO CARTA VERSO CARTA",
  attention_text: "Attenzione",
  attention_message_text:
    "per trasferire i tuoi fondi su un conto/carta devi inserire 'A/C No.' (Numero di conto) Numero a 12 cifre.",
  transfer_type_text: "Tipo di trasferimento",
  source_type_text: "Origine fondi",
  from_account_text: "Dal conto",
  from_card_text: "Dal Carta",
  destination_type_text: "Destinazione fondi",
  to_card_text: "Alla carta",
  to_account_text: "Alla conto",
  description_text: "Descrizione",
  transfer_select_text: "Seleziona...",
  internal_text: "Interno",
  external_text: "Esterno",
  managed_card_text: "Gestione carte",
  managed_account_text: "Gestione conti",
  account_number_receiver_text: "Numero di conto del destinatario",
  transfer_button_text: "Trasferimento",
  consumer_text: "Utente",
  first_name_text: "Nome proprio",
  last_name_text: "Cognome",
  email_text: "Indirizzo email",
  password_text: "Password",
  country_code: "Prefisso internazionale ",
  mobile_no_text: "Numero di cellulare",
  dob_text: "Data di nascita",
  occupation_text: "Professione",
  address_text: "Indirizzo",
  source_of_found: "Fonte di fondi",
  freind_code: "Codice amico",
  term_and_condition_message_text:
    "Ho letto e accetto tutte le norme e le condizioni",
  return_to_text: "Torni alla sezione",
  accounting_text: "CONTABILITÀ",
  audit_text: "AUDIT",
  finance_text: "FINANZA",
  public_sector_text: "SETTORE PUBBLICA AMMINISTRAZIONE",
  art_text: "L'ARTE DELLO SPETTACOLO",
  auto_text: "AUTOMOBILI AVIAZIONE",
  banking_text: "ATTIVITA' BANCARIA CREDITIZIA",
  legal_text: "CONSULENZA AZIENDALE AREA LEGALE",
  repair_text: "COSTRUZIONE RIPARAZIONI",
  education_text: "ISTRUZIONE SERVIZI PROFESSIONALI",
  it_text: "SERVIZI DI INFORMAZIONE",
  tobacco_text: "ALCOOL  TABACCO",
  gaming_text: "GAMING GIOCHI D'AZZARDO",
  medical_text: "SERVIZI MEDICI",
  manufacturing_text: "PRODUZIONE",
  marketig_text: " PUBBLICHE RELAZIONI MARKETING ",
  jewelry_text: "GIOIELLI PREZIOSI",
  ngo_text: "ORGANIZZAZIONE NON GOVERNATIVA",
  insurance_text: "ASSICURAZIONE SICUREZZA",
  retail_text: "COMMERCIO AL DETTAGLIO  COMMERCIO ALL'INGROSSO",
  travel_text: "VIAGGI TURISMO ",
  freelancer_text: "FREELANCE",

  labour_text: "LABOUR CONTRACT",
  civil_text: "CIVIL CONTRACT",
  rent_text: "RENT",
  auxiliary_sources_text: "FUNDS FROM OTHER AUXILIARY SOURCES",
  assets_text: "SALE OF MOVABLE ASSETS",
  estate_text: "SALE OF REAL ESTATE",
  ordinary_text: "ORDINARY BUSINESS ACTIVITY",
  dividends_text: "DIVIDENDS",
  loan_text: "LOAN FROM FINANCIAL INSTITUTIONS CREDIT UNIONS",
  third_parties_text: "LOAN FROM THIRD PARTIES",
  shares_text: "SALE OF COMPANY SHARES BUSINESS",
  personal_text: "PERSONAL SAVINGS",
  family_text: "FAMILY SAVINGS",
  other_text: "OTHER",
  other_source_of_found_text: "Altra fonte di fondi",
  corporate_text: "Aziendale",
  company_position_text: "Posizione aziendale",
  business_type_text: "Tipo di affari",
  business_name_text: "Nome commerciale",
  registration_number_text: "Numero di registrazione",
  registration_country_text: "Paese di registrazione",
  industry_text: "Industria",
  authorised_text: "RAPPRESENTANTE AUTORIZZATO",
  director_text: "DIRETTORE",
  login_again_text: "Accedi di nuovo",
  login_again_message_text:
    "La tua sessione di accesso è scaduta, effettua nuovamente il login!",
  activate_physical_card_text: "Attiva Carta Fisica",
  virtual_text: "Virtuale",
  failed_text: "Fallire",
  failed_message_text: "Pagamento fallito",
  applied_message_text: "Richiesta di sostituzione riuscita!",
  otp_verification_message_text: "OTP verificato con successo!",
  wire_transfer_message_first_text: "Pagamento commissione di",
  active_card_message_first_text: "Ti verranno addebitati",
  for_transfer_text: "per trasferimenti da",
  for_trnsfer_more_text: "per il trasferimento di più di",
  active_card_message_second_text:
    "per l'aggiornamento della nuova carta alla carta fisica",
  activate_button_text: "Attivare",
  activate_physical_card_message_text: "Carta fisica attivata con successo!",
  replace_text: "Replace",
  replace_card_confirmation_message_text: "Perché vuoi sostituire la carta",
  damaged_text: "Danneggiata",
  payment_option_text: "Scegli l'opzione di pagamento ?",
  account_number_text: "Numero di conto",
  suffient_balance_text:
    "Non hai un saldo sufficiente per il trasferimento. Si prega di aggiungere l'importo nel tuo account",
  total_amount_text: "Importo totale",
  payment_amount_text: "Importo del pagamento",
  processing_text: "Tassa di elaborazione",
  resend_otp_message_text: "OTP inviato con successo",
  resend_otp_text: "Invia nuovamente OTP",
  sort_code_text: "Codice di ordinamento",
  card_number_receiver_text: "Numero di carta del destinatario",
  name_of_account_text: "nome dell'account",
  cost_of_account_text: "costo di conto",
  term_conditions_but_text_con_eur: "Termini e Condizioni (Consumatore) EUR",
  term_conditions_but_text_con_uk: "Termini e Condizioni (Consumatore) UK ",
  term_conditions_but_text_corp_eur: "Termini e Condizioni (Aziende) EUR",
  term_conditions_but_text_corp_uk: "Termini e Condizioni (Aziende) UK",
  kyc_text: "Completare la verifica KYC e del telefono",
  kyb_text: "Completare la verifica KYB e del telefono",
  phone_enrollment_message:
    "Si prega di registrare il proprio numero di telefono per l'Autenticazione Forte!",
};

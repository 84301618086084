import React, { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { menuItems } from '../../constants/menuItems';
import useAccountDetails from '../../helpers/customHook/useAccontDetails';
import useCompletedKYB from '../../helpers/customHook/useCompletedKYB';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";

function SideMenuListItem() {
  const location = useLocation();
  const completedKYB = useCompletedKYB();
  const accountDetails = useAccountDetails();
  const { t } = useTranslation();

  return useMemo(
    () =>
      menuItems.map((data, index) => {
        const listItem = (
          <li
            className={location.pathname === data.to ? 'active' : ''}
            key={index}
          >
            <Link to={data.to}>
              <div className="menu_icon">
                <img src={data.inactiveIcon} className="icon_normal" alt="" />
                <img src={data.activeIcon} className="icon_active" alt="" />
              </div>
              <span>{data.title}</span>
            </Link>
          </li>
        );
        if (data.title === t("complete_due_sidebar_text")) {
          if (
            completedKYB === 'APPROVED' &&
            (accountDetails.phone_enrollment === 'ACTIVE' ||
              accountDetails.phone_enrollment !== 'PENDING_VERIFICATION'
            )
            &&  accountDetails.phone_enrollment !== ''
          )
            return null;
          return listItem;
        }
        // adding other items only if kyb approved
        return (
          completedKYB === 'APPROVED' &&
          (accountDetails.phone_enrollment === 'ACTIVE' ||
            accountDetails.phone_enrollment !== 'PENDING_VERIFICATION'
            )
            &&
            accountDetails.phone_enrollment !== ''
            &&
          listItem
        );
      }),
    [location.pathname, completedKYB, accountDetails]
  );
}

export default memo(SideMenuListItem);

import { accountDetailsURLS } from '../constants/urls';

export const getAccountDetails = user => {
  return fetch(accountDetailsURLS.ACCOUNT_DETAIL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'POST',
    body: JSON.stringify({
      type: user.identity.type === 'CORPORATE' ? 'corp' : 'cons',
    }),
  });
};

export const updateCorporateAccount = (payload = {}, user) => {
  return fetch(accountDetailsURLS.UPDATE_CORPORATE_ACCOUNT, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const updateConsumerAccount = (payload = {}, user) => {
  return fetch(accountDetailsURLS.UPDATE_CONSUMER_ACCOUNT, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const updatePassword = (payload = {}, user) => {
  return fetch(accountDetailsURLS.UPDATE_PASSWORD, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const logoutUser = user => {
  return fetch(accountDetailsURLS.LOGOUT, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'POST',
  });
};

export const upgradeAccountKYC = (payload = {}, user) => {
  return fetch(accountDetailsURLS.UPGRADE_KYC, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const initiateKYB = (payload, user) => {
  return fetch(accountDetailsURLS.INITIATE_KYB, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const enrollPhoneSend = user => {
  return fetch(accountDetailsURLS.ENROLL_PHONE_SEND, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'POST',
  });
};

export const enrollPhoneVerify = (payload, user) => {
  return fetch(accountDetailsURLS.ENROLL_PHONE_VERIFY, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

import React, { memo, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import useCompletedKYB from '../helpers/customHook/useCompletedKYB';
import useUser from '../helpers/customHook/useUser';
import Navigation from '../navigation/Navigation';
import FallBack from '../components/fallback/FallBack';
import useAccountDetails from '../helpers/customHook/useAccontDetails';

function PrivateRoute(props) {
  const { children, title, loading, ...rest } = props;
  const user = useUser();
  const accountDetails = useAccountDetails();
  const completedKYB = useCompletedKYB();

  useEffect(() => {
    document.title = title ? title : 'Dignitech';
  }, [title]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // checking for user
        if (user) {
          if (loading) return <FallBack />;
          // if KYB is done, welcome
          if (
            completedKYB === 'APPROVED' &&
            accountDetails.phone_enrollment === 'ACTIVE'
          ) {
            return <Navigation>{children}</Navigation>;
          }
          // if KYB is not done redirect to KYB page
          else {
            if (title !== 'Complete Due Diligence') {
              return (
                <Redirect
                  to={{
                    pathname: '/complete-KYB-KYC',
                    state: { from: location },
                  }}
                />
              );
            } else {
              return <Navigation>{children}</Navigation>;
            }
          }
        }
        // when no user, go to login
        else {
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          );
        }
      }}
    />
  );
}

export default memo(PrivateRoute);

import { lazy } from 'react';
const AllCards = lazy(() => import('../pages/AllCards'));
const BankPaymentType = lazy(() => import('../pages/BankPaymentType'));
const CardLoadFail = lazy(() => import('../pages/CardLoadFail'));
// const CardLoadForm = lazy(() => import('../pages/CardLoadForm'));
const CardToCard = lazy(() => import('../pages/CardToCard'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const Details = lazy(() => import('../pages/Details'));
// const EditAccount = lazy(() => import('../pages/EditAccount'));
const FAQ = lazy(() => import('../pages/FAQ'));
const NewCard = lazy(() => import('../pages/NewCard'));
const OneOffPayment = lazy(() => import('../pages/OneOffPayment'));
const Options = lazy(() => import('../pages/Options'));
const RecurringPayment = lazy(() => import('../pages/RecurringPayment'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));
const Transactions = lazy(() => import('../pages/Transactions'));
const CompleteKYB = lazy(() => import('../pages/CompleteKYB'));
const UpdatePassword = lazy(() => import('../pages/UpdatePassword'));
const AccountDetails = lazy(() => import('../pages/AccountDetails'));
const CardTransactions = lazy(() => import('../pages/CardTransactions'));

export const privateRoutes = [
  {
    path: '/all-cards',
    title: 'All Cards',
    component: <AllCards />,
  },
  {
    path: '/bank-payment-type',
    title: 'Payment Type',
    component: <BankPaymentType />,
  },
  {
    path: '/card-load-fail',
    title: 'Card Load Failed',
    component: <CardLoadFail />,
  },
  // {
  //   path: '/load-card',
  //   title: 'Load Card',
  //   component: <CardLoadForm />,
  // },
  {
    path: '/card-to-card',
    title: 'Card To Card',
    component: <CardToCard />,
  },
  {
    path: '/contact-us',
    title: 'Contact Us',
    component: <ContactUs />,
  },
  {
    path: '/details',
    title: 'Details',
    component: <Details />,
  },
  // {
  //   path: '/edit',
  //   title: 'Edit Account',
  //   component: <EditAccount />,
  // },
  {
    path: '/frequenly-asked-questions',
    title: 'FAQ',
    component: <FAQ />,
  },
  {
    path: '/new-card',
    title: 'New Card',
    component: <NewCard />,
  },
  {
    path: '/one-off-payment',
    title: 'One Off Payment',
    component: <OneOffPayment />,
  },
  {
    path: '/options',
    title: 'Options',
    component: <Options />,
  },
  {
    path: '/recurring-payment',
    title: 'Recurring Payment',
    component: <RecurringPayment />,
  },
  {
    path: '/terms-and-conditions',
    title: 'Terms And Conditions',
    component: <TermsAndConditions />,
  },
  {
    path: '/transactions',
    title: 'Transactions',
    component: <Transactions />,
  },
  {
    path: '/card-transactions',
    title: 'Card Transactions',
    component: <CardTransactions />,
  },
  {
    path: '/complete-KYB-KYC',
    title: 'Complete Due Diligence',
    component: <CompleteKYB />,
  },
  {
    path: '/update-password',
    title: 'Update Password',
    component: <UpdatePassword />,
  },
  {
    path: '/account-details',
    title: 'Account Details',
    component: <AccountDetails />,
  },
];

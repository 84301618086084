import React, { memo } from 'react';

import './fallback.css';

import Spinner from '../spinner/Spinner';

function FallBack() {
  return (
    <div className='fallback'>
      <Spinner size={64} />
    </div>
  );
}

export default memo(FallBack);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_IT } from "./Italian/translations";
import { TRANSLATIONS_EN } from "./English/translations";

const language = localStorage.getItem("languagecode");
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     it: {
       translation: TRANSLATIONS_IT
     }
   }
 });
 
i18n.changeLanguage(language);
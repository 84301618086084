
import React, {
  memo,
} from 'react';

import Footer from '../components/Footer';
import HeaderView from '../components/HeaderView';
import SideMenus from '../components/sideMenu/SideMenus';

function Navigation(props) {
  const { children } = props;

  const pathname = window.location.pathname;
  return (
    
      
    <div>
      {pathname === '/account-details'?
      <>
      <SideMenus />
      <div className='content background-image'>
        <HeaderView />
        <div className='m-t-80'>{children}</div>
        <Footer />
      </div>
      </>
       :
       <>
      <SideMenus />
      <div className='content'>
        <HeaderView />
        <div className='m-t-80'>{children}</div>
        <Footer />
      </div>
      </> }
      </div>
     
    
  );
}

export default memo(Navigation);

const actionLabels = {
  SET_USER: 'SET_USER',
  SET_REGISTERED_USER: 'SET_REGISTERED_USER',
  SET_ACCOUNT_DETAILS: 'SET_ACCOUNT_DETAILS',
  SET_KYB: 'SET_KYB',
  LOGOUT: 'LOGOUT',
  SET_CARDS: 'SET-CARDS',
  SET_FEES: 'SET_FEES',
};

export default actionLabels;

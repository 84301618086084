import actionLabels from '../actions/actionLabels';

const user = sessionStorage.getItem('user');

const initialState = {
  user: user ? JSON.parse(user) : null,
  registeredUser: null,
  accountDetails: null,
  completedKYB: false,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionLabels.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case actionLabels.SET_REGISTERED_USER:
      return {
        ...state,
        registeredUser: action.payload.registeredUser,
      };
    case actionLabels.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.payload.accountDetails,
      };
    case actionLabels.SET_KYB:
      return {
        ...state,
        completedKYB: action.payload.completedKYB,
      };
    case actionLabels.LOGOUT:
      sessionStorage.clear();
      window.location.href = '/';
      return {
        user: null,
        registeredUser: null,
        accountDetails: null,
        completedKYB: false,
      };
    default:
      return state;
  }
};

export default userReducer;

import React, { useEffect } from "react";

import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/fonts/font.css";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import Router from "./router/Router";
import { WEAVR_UI_KEY } from "./constants/weavrUIKey";
import LangagueModel from "./pages/LangagueModel";
const $ = window.jQuery;
function App() {
  useEffect(() => {
    window.OpcUxSecureClient.init(WEAVR_UI_KEY);
  }, []);

  useEffect(() => {
    const language = localStorage.getItem("language");
    if (language == null) {
      $("#exampleModalCenter").modal("show");
    } else {
      $("#exampleModalCenter").modal("hide");
    }
  }, []);
  return (
    <>
      <Router />
      <LangagueModel />
    </>
  );
}

export default App;

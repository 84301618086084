import swal from 'sweetalert';
import { t } from 'i18next';
import { logout } from '../redux/actions/userActions';

const handleErrorCodes = (data, dispatch, user) => {
  if (data.status === 401) {
    swal(
      t("login_again_text"),
      t("login_again_message_text"),
      'info'
    );
    dispatch(logout(user));
    return;
  }

  if (typeof data.errors === 'string') {
    swal('Error', data.errors.replaceAll('_', ' '), 'error');
    return;
  }

  switch (data.status) {
    case 400:
      if (typeof data.errors === 'object') {
        let constructedString = '';
        for (const key of Object.keys(data.errors)) {
          for (const stringValue of data.errors[key]) {
            constructedString += stringValue;
          }
          constructedString += '\n';
        }
        swal('Error', constructedString, 'error');
      } else
        swal(
          'Invalid',
          'Your request was invalid, please check all the details and try again!',
          'warning'
        );
      return;
    case 404:
      swal('Not Found', 'Your requested resource was not found!', 'warning');
      return;
    case 210:
      swal('Error', 'Your request was resulted to error!', 'error');
      return;
    case 409:
      if (typeof data.errors?.errorCode === 'string') {
        swal('Conflict', data.errors?.errorCode.replaceAll('_', ' '), 'error');
      } else {
        swal(
          'Conflict',
          'Your request conflicts with out data!\nOne or more input data might already exist in the system!\nTry different value!',
          'error'
        );
      }
      return;
    default:
      swal('Conflict', 'Something went wrong!', 'error');
  }
};

export default handleErrorCodes;

import React from 'react'

export default function Language() {
    const changeLanguage = async (element) =>{
        let code = 'en';
        if(element == 'english'){
            code ='en';
        }else{
            code ='it';
        }
        window.localStorage.setItem('language', element);
        window.localStorage.setItem('languagecode', code);
        window.localStorage.setItem('i18nextLng', code);
        window.location.reload();
    }
   

    return (
        <select className=""
            onChange={(e) => changeLanguage(e.target.value)}
        >
            <option value="null">Select Language</option>
            <option value="english">English</option>
            <option value="italian">Italian</option>
        </select>
    )
}

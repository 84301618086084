import React, {
  lazy,
  Suspense,
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { privateRoutes } from '../constants/privateRoutes';
import FallBack from '../components/fallback/FallBack';
import PrivateRoute from './PrivateRoute';
import { getAccountDetails } from '../APIs/accountsAPI';
import handleErrorCodes from '../helpers/handleErrorCodes';
import { setAccountDetails, setKYB } from '../redux/actions/accountActions';
import useUser from '../helpers/customHook/useUser';
import { contoEnjoyFees } from '../APIs/contoEnjoyFeesAPI';
import { setFees } from '../redux/actions/contoEnjoyFeesAction';
import { logout } from '../redux/actions/userActions';
import swal from 'sweetalert';
import useAccountDetails from '../helpers/customHook/useAccontDetails';
import { t } from 'i18next';
import IdleLogout from "../components/IdleLogout";

const Login = lazy(() => import('../pages/Login'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const Register = lazy(() => import('../pages/Register'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const VerifyEmail = lazy(() => import('../pages/VerifyEmail'));
const DirectorKYC = lazy(() => import('../pages/DirectorKYC'));


function Router() {
  const dispatch = useDispatch();
  const user = useUser();
  const accountDetails = useAccountDetails();

  const [loading, setLoading] = useState(false);
  



  useEffect(() => {
    if (accountDetails) {
      contoEnjoyFees({
        plan: accountDetails.response.feeGroup,
      })
        .then(data => {
          dispatch(setFees(data.response));
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [dispatch, accountDetails]);
 

  const accountDetailsOperation = useCallback(() => {
    if (user) {
      setLoading(true);
      getAccountDetails(user)
        .then(body => body.json())
        .then(data => {
          setLoading(false);
          if (data.status === 200 || data.status === 201) {
            const KYBStatus =
              data.kycstatus.kybStatus || data.kycstatus.fullDueDiligence;
            dispatch(setAccountDetails(data));
            dispatch(setKYB(KYBStatus));
          } else {
            handleErrorCodes(data, dispatch, user);
          }
        })
        .catch(e => {
          setLoading(false);
          swal(
            t("login_again_text"),
            t("login_again_message_text"),
            'info'
          );
          dispatch(logout(user));
        });
    } else {
      dispatch(setAccountDetails(null));
    }
  }, [user, dispatch]);

  useEffect(() => {
    accountDetailsOperation();
  }, [accountDetailsOperation]);

  return (
    
    <BrowserRouter>
     
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        {useMemo(
          () =>
            privateRoutes.map((route, index) => (
              <PrivateRoute
                key={index}
                path={route.path}
                exact
                title={route.title}
                loading={loading}
              >
                <Suspense fallback={<FallBack />}>{route.component}</Suspense>
              </PrivateRoute>
            )),
          [loading]
        )}
        <Route path="/login">
          <Suspense fallback={<FallBack />}>
            <Login />
          </Suspense>
        </Route>
        <Route path="/register">
          <Suspense fallback={<FallBack />}>
            <Register />
          </Suspense>
        </Route>
        <Route path="/set-password/:nonce/:email">
          <Suspense fallback={<FallBack />}>
            <ResetPassword />
          </Suspense>
        </Route>
        <Route path="/verify-email">
          <Suspense fallback={<FallBack />}>
            <VerifyEmail />
          </Suspense>
        </Route>
        <Route path="/forgot-password">
          <Suspense fallback={<FallBack />}>
            <ForgotPassword />
          </Suspense>
        </Route>
        <Route path="/corporates/kyc">
          <Suspense fallback={<FallBack />}>
            <DirectorKYC />
          </Suspense>
        </Route>
        <Route path="*">
          <Suspense fallback={<FallBack />}>
            <PageNotFound />
          </Suspense>
        </Route>
      </Switch>
      <IdleLogout />
    </BrowserRouter>
    
  );
}

export default memo(Router);

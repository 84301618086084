import icon1 from '../assets/images/icon_01.png';
import icon1active from '../assets/images/icon_01_active.png';
import icon2 from '../assets/images/icon_02.png';
import icon2active from '../assets/images/icon_02_active.png';
import icon3 from '../assets/images/icon_03.png';
import icon3active from '../assets/images/icon_03_active.png';
import icon4 from '../assets/images/icon_04.png';
import icon4active from '../assets/images/icon_04_active.png';
// import icon5 from '../assets/images/icon_05.png';
// import icon5active from '../assets/images/icon_05_active.png';
import icon7 from '../assets/images/icon_07.png';
import icon7active from '../assets/images/icon_07_active.png';
import icon11 from '../assets/images/icon_11.png';
import icon11active from '../assets/images/icon_11_active.png';
import icon12 from '../assets/images/icon_12.png';
import icon12active from '../assets/images/icon_12_active.png';
import { t } from 'i18next';


export const menuItems = [
  {
    title: t("complete_due_sidebar_text"),
    to: '/complete-KYB-KYC',
    inactiveIcon: icon1,
    activeIcon: icon1active,
  },
  {
    title: t("view_account_text"),
    to: '/account-details',
    inactiveIcon: icon1,
    activeIcon: icon1active,
  },
  // {
  //   title: 'Edit Account',
  //   to: '/edit',
  //   inactiveIcon: icon2,
  //   activeIcon: icon2active,
  // },
  {
    title: t("transaction_history_sidebar_text"),
    to: '/transactions',
    inactiveIcon: icon3,
    activeIcon: icon3active,
  },
  {
    title: t("all_card_sidebar_text"),
    to: '/all-cards',
    inactiveIcon: icon4,
    activeIcon: icon4active,
  },
  // {
  //   title: 'Load Funds',
  //   to: '/load-card',
  //   inactiveIcon: icon5,
  //   activeIcon: icon5active,
  // },
  {
    title: t("payment_request_sidebar_text"),
    to: '/one-off-payment',
    inactiveIcon: icon7,
    activeIcon: icon7active,
  },
  {
    title: t("update_password_sidebar_text"),
    to: '/update-password',
    inactiveIcon: icon2,
    activeIcon: icon2active,
  },
  {
    title: t("term_and_condition_sidebar_text"),
    to: '/terms-and-conditions',
    inactiveIcon: icon11,
    activeIcon: icon11active,
  },
  {
    title: t("contact_us_sidebar_text"),
    to: '/contact-us',
    inactiveIcon: icon12,
    activeIcon: icon12active,
  },
];

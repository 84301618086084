import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useAccountDetails from '../helpers/customHook/useAccontDetails';
import useUser from '../helpers/customHook/useUser';
import { logout } from '../redux/actions/userActions';
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import Language from '../pages/Language';

function HeaderView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const accountDetails = useAccountDetails();

  const [username, setUsername] = useState('');

  useEffect(() => {
    if (accountDetails) {
      setUsername(accountDetails.response?.rootUser?.name);
    }
  }, [accountDetails]);

  const handleLogout = useCallback(() => {
    dispatch(logout(user));
  }, [dispatch, user]);

  return (
    <>
      <div className="page_header d-new-a">
        <h3>
         {t("hi_text")} {username}
          <span />
        </h3>
        <div className="page_header_right d-align">
          {/* <div className='notification'>
            <a href='/'>
              <img src={notificationIcon} alt='' />
              <span>3</span>
            </a>
          </div> */}

        <div>
          language:
          <Language />
        </div>

          <div className="singout" onClick={handleLogout}>
            {/* <a href=''>Logout</a> */}
            {/* Logout */}
            {t("logout_text")}
          </div>
        </div>
      </div>

      <div className="m-t-80">
        <div className="mobile_text">
          <h3>
          {t("hi_text")} <span>{username}</span>
          </h3>
        </div>
      </div>
    </>
  );
}

export default memo(HeaderView);

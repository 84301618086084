import actionLabels from '../actions/actionLabels';

const contoEnjoyFeesReducer = (state = {}, action) => {
  switch (action.type) {
    case actionLabels.SET_FEES:
      return { ...action.payload };
    default:
      return state;
  }
};

export default contoEnjoyFeesReducer;

export const TRANSLATIONS_EN = {
  login_text: "Enter your Credentials to login.",
  login_username_text: "Username or Email",
  login_passowrd_text: "Password",
  palceholder_passowrd_text: "Password",
  enter_here_text: "Enter Here*",
  login: "LOGIN",
  fpassword_text: "Forgot Password?",
  note: "Note",
  note_before: "Fields with",
  note_after: "are mandatory",
  hi_text: "Hi",
  logout_text: "Logout",
  account_details_text: "Account Details",
  full_name_text: "Full Name",
  company_name_text: "Company Name",
  address_line_1: "Address Line 1",
  address_line_2: "Address Line 2",
  city_text: "City",
  postcode_text: "Postcode / Zip",
  country_text: "Country",
  mobile_number_text: "Mobile Number",
  a_c_no_text: "Managed A/C No",
  IBAN_text: "IBAN",
  BIC_text: "BIC",
  account_balance_text: "Account Balance",
  currency_text: "Currency",
  bank_transfer_text: "Top-up using Bank Transfer",
  bank_transfer_other_text:
    "To top-up the account ContoEnjoy you will need to send a bank transfer from your personal account to the IBAN of ContoEnjoy",
  transaction_history_text: "Transaction History",
  current_available_balance_text: "Current Available Balance",
  date_range_text: "Select a date range to view your transactions",
  transaction_history_account_text: "Transaction History Account",
  download_pdf_button_text: "Download Transaction History pdf",
  download_csv_button_text: "Download Transaction History csv",
  txn_date_text: "Txn Date",
  id_text: "ID",
  type_text: "Type",
  amount_text: "Amount",
  fee_text: "Fee",
  beneficiary_text: "Beneficiary",
  transaction_history_cards_text: "Transaction History Cards",
  from_text: "From",
  to_text: "To",
  go_text: "GO",
  clear_text: "CLEAR",
  your_account_text: "Cards in Your Account",
  card_holder_name_text: "Card Holder Name",
  card_number_text: "Card Number",
  card_id_text: "Card ID",
  expiry_date_text: "Expiry Date",
  status_text: "Status",
  balance_text: "Balance",
  actions_text: "Actions",
  block_text: "Block",
  destroy_text: "Destroy",
  replace_card_text: "Replace Card",
  view_card_details_text: "View Card Details",
  physical_text: "Physical",
  apply_new_card_text: "Apply For A Card",
  card_transfer_text: "Card To Card Transfer",
  apply_for_new_card_text: "Apply for a new card",
  one_off_payment: "Make a one-off payment",
  creditor_name_text: "Creditor Name",
  creaditot_iban_text: "Creditor IBAN",
  bank_identification_text: "Bank Identification Code",
  transaction_reference_text: "Transaction Reference",
  swift_code_text: "Bank Identification Code",
  reference_text: "Reference",
  submit_button_text: "SUBMIT",
  update_password_text: "Update Password",
  old_password_text: "Old Password",
  new_password_text: "New Password",
  password_enter_here_text: "Enter Here*",
  update_button_text: "Update",
  contact_us_text: "Contact Us",
  call_text: "Call",
  support_text: "Support",
  fecebook_text: "Facebook",
  instragram_text: "Instagram",
  website_text: "Website",
  Forget_password_text: "Forgot Password",
  enter_email: "Enter your email",
  Forget_password_palceholder_text: "e.g. name@example.com",
  send_link_text: "SEND LINK",
  success_text: "Success!",
  forget_password_s_text: "We have sent recovery link to your email!",
  ok_text: "OK",
  footer_text1:
    "The Payment account and Mastercard cards are issued by Paynetics AD (via Paystratus Group Ltd, a Technical Provider of Paynetics AD) with company address 76A James Bourchier Blvd, 1407 Sofia, Bulgaria, company registration number 131574695 and VAT BG131574695, pursuant to license by Mastercard International Incorporated and Bulgarian National Bank.",
  footer_text2:
    "To ensure your funds are safe Paynetics follow a process known as ‘safeguarding’ which is a regulatory requirement for all EMIs. In this process Paynetics keeps your money separate from its own money and place it in a safeguarding account with a Bank.",
  footer_text3:
    "Electronic Money issued is not covered by any Deposit Guarantee Scheme which is a government backed scheme offering protection to customers’ funds of up to € 100,000 (£ 85,000 in the UK under the FSCS) per customer. However, in the event of an insolvency, your funds would remain in the safeguarded account at the designated Bank and separated from Paynetics’ accounts.",
  footer_text4:
    "In the event of Paynetics going out of business, an insolvency practitioner would be appointed to return the funds Paynetics has safeguarded to the customers. This means you would get most of your funds back, except for the costs deducted by the insolvency practitioner for returning the funds to the customers.",
  footer_text5:
    "For programmes operating in the United Kingdom, the Payment Account and Mastercard cards are issued by Paynetics UK, Company number 1248133, (via Paystratus Group Ltd, a Technical Provider of Paynetics UK). Paynetics UK is a wholly owned subsidiary of Paynetics AD with its registered address at 1st Floor, 18 Devonshire Row, London, England, EC2M 4RH. Paynetics UK is an electronic money institution authorised and regulated by the Financial Conduct Authority (firm reference number 942777) for the issuance of e-money and provision of payment services in the UK.",
  footer_text6:
    "For programmes operating in the European Union, the Payment account and Mastercard cards are issued by Paynetics AD (via Paystratus Group Ltd, a Technical Provider of Paynetics AD) with company address 76A James Bourchier Blvd, 1407 Sofia, Bulgaria, company registration number 131574695 and VAT BG131574695, pursuant to license by Mastercard International Incorporated and Bulgarian National Bank.",
  footer_text7:
    "Weavr (Europe) Ltd is acting on behalf of Paynetics AD, as a registered agent on the territory of the EU member states, and is entered into the public register of e-money institutions’ agents kept by the Bulgarian National Bank.",
  footer_text8:
    "Copyright © 2022 ContoEnjoy White Financial UAB UK – TAY HOUSE 300 BATH STREET GLASGOW SCOTLAND G2 4JR – VAT NUMBER GB 432 7848 76 ",
  file_ready_title_text: "File Ready",
  file_ready_text: "Please click on the download button to download the file",
  cancel_button_text: "Cancel",
  download_button_text: "Download",
  view_account_text: "View Account",
  complete_due_sidebar_text: "Complete Due Diligence",
  transaction_history_sidebar_text: "Transaction History",
  all_card_sidebar_text: "All Cards",
  payment_request_sidebar_text: "Payment Request",
  update_password_sidebar_text: "Update Password",
  term_and_condition_sidebar_text: "Terms and Conditions",
  contact_us_sidebar_text: "Contact Us",
  verify_email_text: "Please Verify Your Email",
  verify_phone_text: "Please Verify Your phone",
  enter_code_here_text: "Enter the code here",
  verify_email_placeholder_text: "e.g. 123456",
  verify_button_text: "VERIFY",
  email_verify_success_message_text: "Your email was verified successfully!",
  phone_verify_success_message_text: "Your phone was verified successfully!",
  pay_your_fees_text: "Pay your Registration fee to continue",
  card_details_text: "Your card details",
  payment_success_message_text: "Your payment was successful!",
  enroll_phone_button_text: "ENROLL PHONE",
  setup_phone_token_button_text: "SETUP PHONE TOKEN",
  // initiate_KYB_buttin_text:"INITIATE KYB",
  // initiate_KYc_buttin_text:"INITIATE KYC",
  KYB_text: "KYB",
  KYC_text: "KYC",
  initiate_buttin_text: "INITIATE",

  otp_send_mobile_text: "We have sent the OTP, please check your mobile!",
  sent_text: "Sent!",
  enter_otp_here_text: "Enter OTP here",
  otp_text: "OTP",
  verify_enroll_button_text: "VERIFY ENROLL",
  verify_setup_token_button_text: "VERIFY PHONE SETUP TOKEN",
  actual_balance_text: "Actual Balance",
  card_cvv_text: "Card CVV",
  card_brand_text: "Card Brand",
  card_model_text: "Card Mode",
  card_type_text: "Card Type",
  card_pin_text: "Card PIN",
  close_text: "CLOSE",
  apply_card_text: "Apply for a new card",
  name_text: "Name and Surname on Card or Company Name",
  billing_address_1_text: "Billing Address 1",
  billing_address_2_text: "Billing Address 2",
  post_code_text: "Post Code",
  card_holder_mobile_no_text: "Card Holder Mobile No.",
  select_country_text: "Select Country",
  loding_details_text: "To change your loading card details please",
  apply_button_text: "APPLY",
  state_text: "State",
  select_dial_code_text: "Select Dial Code",
  card_to_card_text: "Card to Card Transfer",
  attention_message_text:
    "To transfer your funds to an account / card you must enter 'A/C No.' (Account number) 12-digit number.",
  attention_text: "Attention",
  transfer_type_text: "Transfer Type",
  source_type_text: "Source Type",
  from_account_text: "From Account",
  from_card_text: "From Card",
  destination_type_text: "Destination Type",
  to_card_text: "To Card",
  to_account_text: "To Account",
  description_text: "Description",
  transfer_select_text: "select...",
  internal_text: "Internal",
  external_text: "External",
  managed_card_text: "Managed Cards",
  managed_account_text: "Managed Accounts",
  account_number_receiver_text: "Account Number of Receiver",
  transfer_button_text: "TRANSFER",
  consumer_text: "Consumer",
  first_name_text: "First Name",
  last_name_text: "Last Name",
  email_text: "Email",
  password_text: "Password",
  country_code: "Country Code",
  mobile_no_text: "Mobile No",
  dob_text: "Date Of Birth",
  occupation_text: "Occupation",
  address_text: "Address",
  source_of_found: "Source Of Funds",
  freind_code: "Freind Code",
  term_and_condition_message_text:
    "I have read and accept all terms and conditions",
  return_to_text: "Return to",

  accounting_text: "ACCOUNTING",
  audit_text: "AUDIT",
  finance_text: "FINANCE",
  public_sector_text: "PUBLIC SECTOR ADMINISTRATION",
  art_text: "ART ENTERTAINMENT",
  auto_text: "AUTO AVIATION",
  banking_text: "BANKING LENDING",
  legal_text: "BUSINESS CONSULTANCY LEGAL",
  repair_text: "CONSTRUCTION REPAIR",
  education_text: "EDUCATION PROFESSIONAL SERVICES",
  it_text: "INFORMATIONAL TECHNOLOGIES",
  tobacco_text: "TOBACCO ALCOHOL",
  gaming_text: "GAMING GAMBLING",
  medical_text: "MEDICAL SERVICES",
  manufacturing_text: "MANUFACTURING",
  marketig_text: "PR MARKETING",
  jewelry_text: "PRECIOUS GOODS JEWELRY",
  ngo_text: "NON GOVERNMENTAL ORGANIZATION",
  insurance_text: "INSURANCE SECURITY",
  retail_text: "RETAIL WHOLESALE",
  travel_text: "TRAVEL TOURISM",
  freelancer_text: "FREELANCER",
  labour_text: "LABOUR CONTRACT",
  civil_text: "CIVIL CONTRACT",
  rent_text: "RENT",
  auxiliary_sources_text: "FUNDS FROM OTHER AUXILIARY SOURCES",
  assets_text: "SALE OF MOVABLE ASSETS",
  estate_text: "SALE OF REAL ESTATE",
  ordinary_text: "ORDINARY BUSINESS ACTIVITY",
  dividends_text: "DIVIDENDS",
  loan_text: "LOAN FROM FINANCIAL INSTITUTIONS CREDIT UNIONS",
  third_parties_text: "LOAN FROM THIRD PARTIES",
  shares_text: "SALE OF COMPANY SHARES BUSINESS",
  personal_text: "PERSONAL SAVINGS",
  family_text: "FAMILY SAVINGS",
  other_text: "OTHER",
  other_source_of_found_text: "Other Source Of Funds",
  corporate_text: "Corporate",
  company_position_text: "Company Position",
  business_type_text: "Business Type",
  business_name_text: "Business Name",
  registration_number_text: "Registration Number",
  registration_country_text: "Registration Country",
  industry_text: "Industry",
  authorised_text: "AUTHORISED REPRESENTATIVE",
  director_text: "DIRECTOR",
  login_again_text: "Login Again",
  login_again_message_text:
    "Your login session was expired, please login again!",
  activate_physical_card_text: "Activate Physical Card",
  virtual_text: "Virtual",
  failed_text: "Fail",
  failed_message_text: "Payment Failed",
  applied_message_text: "replace card request Successfully!",
  otp_verification_message_text: "OTP verified successfully!",
  active_card_message_first_text: "You will be charged",
  wire_transfer_message_first_text: "You will be charged",
  for_transfer_text: "for transferring between",
  for_trnsfer_more_text: "for transferring more than",
  active_card_message_second_text: "for upgrading new card to physical card",
  activate_button_text: "Activate",
  activate_physical_card_message_text: "Activated physical card susscessfully!",
  replace_text: "Replace",
  replace_card_confirmation_message_text: "Why you want to replace the card ",
  damaged_text: "Damaged",
  payment_option_text: "Choose Payment option ?",
  account_number_text: "Account Number",
  suffient_balance_text:
    "You don't have sufficient balance to transfer. Please add amount in your account",
  total_amount_text: "Total Amount",
  payment_amount_text: "Payment Amount",
  processing_text: "Processing fee",
  resend_otp_message_text: "OTP Sent Successfully",
  resend_otp_text: "Resend OTP",
  sort_code_text: "Sort Code",
  card_number_receiver_text: "Card Number of Receiver",
  name_of_account_text: "Name of account",
  cost_of_account_text: "Cost of account",
  term_conditions_but_text_con_eur: "Terms and Conditions (Consumer) EUR",
  term_conditions_but_text_con_uk: "Terms and Conditions (Consumer) UK",
  term_conditions_but_text_corp_eur: "Terms and Conditions (Corporate) EUR",
  term_conditions_but_text_corp_uk: "Terms and Conditions (Corporate) UK",
  kyc_text: "Complete KYC and Phone Verification",
  kyb_text: "Complete KYB and Phone Verification",
  phone_enrollment_message:
    "Please enroll your phone number for Strong Authentication!",
};
